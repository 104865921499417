'use client';

import dynamic from 'next/dynamic';
import { adjustHue, hslToColorString, parseToHsl } from 'polished';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { useMeasure } from 'react-use';

import { blockSpacingClassName } from '@/components/Blocks';
import { Carousel } from '@/components/Carousel';
import { Button, Heading, Show, Switch as SwitchBase, Text, View } from '@/components/Primitives';
import { Lexical } from '@/components/Primitives/Lexical';
import { currency } from '@/js/currency';
import { cn } from '@/js/helper';
import { colors } from '@/js/theme';

import type { Props } from './types';
import type { SwitchProps } from '@/components/Primitives';
import type { ElementRef, PropsWithChildren } from 'react';
import type { IconBaseProps } from 'react-icons';

const Form = dynamic(() => import('./form').then((module) => module.PricingPlanForm), { ssr: false });

const PricingPlans: React.FC<Props> = ({ data: plans, ...props }) => {
	const [isFormVisible, setIsFormVisible] = useState(false);
	const [isPaymentPeriodSwitchChecked, setIsPaymentPeriodSwitchChecked] = useState(true);
	const [isPlanPeriodSwitchChecked, setIsPlanPeriodSwitchChecked] = useState(true);
	const [selectedPlanName, setSelectedPlanName] = useState<string | null>(null);
	const [useCarousel, setUseCarousel] = useState(false);

	const [containerRef, containerMeasure] = useMeasure<ElementRef<'div'>>();
	const [itemsRef, itemsMeasure] = useMeasure<ElementRef<'div'>>();
	const itemsWidth = useRef(0);

	useEffect(() => {
		if (itemsMeasure.width === 0) {
			return;
		}

		if (containerMeasure.width <= itemsMeasure.width) {
			itemsWidth.current = itemsMeasure.width;
			setUseCarousel(true);
		} else {
			setUseCarousel(false);
		}
	}, [containerMeasure, itemsMeasure]);

	useEffect(() => {
		if (!isPlanPeriodSwitchChecked) {
			setIsPaymentPeriodSwitchChecked(false);
		}
	}, [isPlanPeriodSwitchChecked]);

	const title = !plans.title ? null : (
		<Heading className='py-16' level={2} primary watermark={plans.titleWatermark ?? undefined}>
			{plans.title}
		</Heading>
	);

	const description = !plans.description ? null : (
		<View>
			<Lexical>{plans.description}</Lexical>
		</View>
	);

	const mappedPlans = useMemo(
		() =>
			plans.items?.map((plan) => {
				const items = !Array.isArray(plan.items)
					? null
					: plan.items.map((item) => {
							if (typeof item === 'number') {
								return item;
							}

							return (
								<View className='mb-4 flex-row' key={item.id}>
									<FaCheck className='mr-2 mt-1.5 shrink-0' />
									<Text>{item.label}</Text>
								</View>
							);
						});

				let price = 0;

				if (isPaymentPeriodSwitchChecked && isPlanPeriodSwitchChecked) {
					price = plan.pricePerMonthYearlyOneYear;
				} else if (!isPaymentPeriodSwitchChecked && isPlanPeriodSwitchChecked) {
					price = plan.pricePerMonthMonthlyOneYear;
				} else if (isPaymentPeriodSwitchChecked && !isPlanPeriodSwitchChecked) {
					price = plan.pricePerMonthYearlyOneMonth;
				} else {
					price = plan.pricePerMonthMonthlyOneMonth;
				}

				const priceOnetime = !plan.priceOneTime ? null : (
					<View className='flex-row justify-center'>
						<Text className='text-sm'>{`+ einmalig ${currency(plan.priceOneTime).format()}`}</Text>
						<Text className='ml-1 self-start text-[0.6em]'>1</Text>
					</View>
				);

				return (
					<View
						className='mb-[0.4rem] mr-[0.4rem] w-80 max-w-full rounded-2xl bg-gradient-to-br from-[var(--gradient-from)] to-[var(--gradient-to)] p-0.5 shadow-[0.4rem_0.4rem_0rem] shadow-primary-200'
						key={plan.id}
						style={{
							'--gradient-from': adjustHue(40, colors.primary.DEFAULT),
							'--gradient-to': adjustHue(-10, colors.primary.DEFAULT),
						}}
					>
						<View className='rounded-[calc(theme(borderRadius.2xl)_-_0.1em)] bg-white p-8'>
							<Heading className='mt-0 self-center' level={3}>
								{plan.name}
							</Heading>

							<View className='flex-row items-center self-center'>
								<Text className='mr-1 text-[4em] font-bold'>{currency(price).dollars()}</Text>
								<View className='mr-3'>
									<Text className='text-[2em] leading-none'>{`,${currency(price).cents().toString().padStart(2, '0')}`}</Text>
									<Text className='text-[0.8em] text-slate-500'>{'pro Monat'}</Text>
								</View>
								<Text className='text-[4em]'>{'€'}</Text>
								<Text className='mt-4 self-start text-[0.8em]'>1</Text>
							</View>

							{priceOnetime}

							<View className='-mb-4 mt-8'>{items}</View>

							<View className='mt-8 items-center'>
								<Button
									onClick={() => {
										setSelectedPlanName(plan.name);
										setIsFormVisible(true);
									}}
									primary
									showArrow
								>
									Auswählen
								</Button>
							</View>
						</View>
					</View>
				);
			}),
		[plans, isPaymentPeriodSwitchChecked, isPlanPeriodSwitchChecked],
	);

	return (
		<View
			className={cn({
				'bg-primary-50': !!plans.meta?.coloredBackground,
			})}
			id={plans.meta?.anchor ?? undefined}
		>
			<View className={blockSpacingClassName}>
				{title}
				{description}

				<View className='-mb-4 mt-8 items-center'>
					<View>
						<SwitchContainer>
							<PeriodLabelContainer>
								<PeriodLabelIcon isActive={!isPlanPeriodSwitchChecked} />
								<PeriodLabel isActive={!isPlanPeriodSwitchChecked}>monatlich kündbar</PeriodLabel>
							</PeriodLabelContainer>
							<Switch
								aria-label='Schalter zum Festlegen ob monatliche jährliche Laufzeit'
								checked={isPlanPeriodSwitchChecked}
								onChange={(value) => setIsPlanPeriodSwitchChecked(value)}
							/>
							<PeriodLabelContainer>
								<PeriodLabelIcon isActive={isPlanPeriodSwitchChecked} />
								<PeriodLabel isActive={isPlanPeriodSwitchChecked}>12 Monate Laufzeit</PeriodLabel>
							</PeriodLabelContainer>
						</SwitchContainer>

						<SwitchContainer>
							<PeriodLabelContainer>
								<PeriodLabelIcon isActive={!isPaymentPeriodSwitchChecked} />
								<PeriodLabel isActive={!isPaymentPeriodSwitchChecked}>monatliche Zahlung</PeriodLabel>
							</PeriodLabelContainer>
							<Switch
								aria-label='Schalter zum Festlegen ob monatliche oder jährlche Zahlung'
								checked={isPaymentPeriodSwitchChecked}
								disabled={!isPlanPeriodSwitchChecked}
								onChange={(value) => setIsPaymentPeriodSwitchChecked(value)}
							/>
							<PeriodLabelContainer>
								<PeriodLabelIcon isActive={isPaymentPeriodSwitchChecked} />
								<PeriodLabel isActive={isPaymentPeriodSwitchChecked}>jährliche Zahlung</PeriodLabel>
							</PeriodLabelContainer>
						</SwitchContainer>
					</View>
				</View>
			</View>

			<View ref={containerRef}>
				{!useCarousel && mappedPlans && (
					<View className='m-auto shrink-0 flex-row items-start gap-8' ref={itemsRef}>
						{mappedPlans}
					</View>
				)}
				{useCarousel && mappedPlans && (
					<Carousel
						containerClassName={cn('mx-12 items-start gap-4')}
						nextButton={
							<div className='absolute right-0 top-12 h-full'>
								<Carousel.NextButton className='sticky left-auto right-0 top-20 h-auto' />
							</div>
						}
						prevButton={
							<div className='absolute left-0 top-12 h-full'>
								<Carousel.PrevButton className='sticky top-20 h-auto' />
							</div>
						}
						slides={mappedPlans}
					/>
				)}
			</View>

			<View className={blockSpacingClassName}>
				<Show when={plans.footNote}>
					<View className='mt-8 text-center'>
						<Lexical className='text-sm'>{plans.footNote}</Lexical>
					</View>
				</Show>
			</View>

			<Show when={isFormVisible && selectedPlanName}>
				<Form
					close={useCallback(() => setIsFormVisible(false), [])}
					planDescription={`${selectedPlanName!}, ${
						!isPlanPeriodSwitchChecked ? 'monatlich Kündbar' : '12 Monate Laufzeit'
					}, ${isPaymentPeriodSwitchChecked ? 'jährliche Zahlung' : 'monatliche Zahlung'}`}
					planName={selectedPlanName!}
				/>
			</Show>
		</View>
	);
};

const SwitchContainer = ({ children }: PropsWithChildren) => {
	return (
		<View className='mb-4 grid grid-cols-[auto_1fr] flex-row items-center gap-2 sm:grid-cols-[1fr_auto_1fr] sm:gap-x-8'>
			{children}
		</View>
	);
};

const Switch = ({ className, ...props }: SwitchProps) => {
	return (
		<SwitchBase
			checkedIcon={false}
			className={cn('row-start-1 row-end-3 sm:col-start-2', className)}
			offColor={hslToColorString(parseToHsl(colors.primary.DEFAULT))}
			onColor={hslToColorString(parseToHsl(colors.primary.DEFAULT))}
			uncheckedIcon={false}
			{...props}
		/>
	);
};

const PeriodLabelContainer = ({ children }: PropsWithChildren) => {
	return <View className='flex-1 basis-52 flex-row items-center'>{children}</View>;
};

const PeriodLabel = ({ children, isActive }: PropsWithChildren<{ isActive: boolean }>) => {
	return (
		<Text
			className={cn({
				'opacity-40': !isActive,
			})}
		>
			{children}
		</Text>
	);
};

const PeriodLabelIcon = ({ isActive }: IconBaseProps & { isActive: boolean }) => {
	return (
		<FaCheck
			className={cn('mx-2 transition-opacity duration-300', {
				'opacity-0': !isActive,
			})}
		/>
	);
};

export { PricingPlans };
