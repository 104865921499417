import React from 'react';

import { Footer as Component } from './component';
import { getFooter } from './functions';

import type { PropsBase } from '@/types/common';

export interface ContainerProps extends PropsBase {}

const Footer: React.FC<ContainerProps> = async (props) => {
	const footer = await getFooter({ apiUrl: props.apiUrl });

	return (
		<Component
			about={footer.about ?? null}
			additionalInfo={footer.additionalInfo ?? null}
			id={footer.id ?? ''}
			linkColumns={footer.linkColumns ?? []}
			logo={footer.logo ?? null}
			officeHours={footer.officeHours ?? []}
			officeHoursNoteBottom={footer.officeHoursNoteBottom ?? null}
			officeHoursNoteTop={footer.officeHoursNoteTop ?? null}
			officeHoursTitle={footer.officeHoursTitle ?? null}
			{...props}
		/>
	);
};

export { Footer };
