'use client';

import React, { useMemo } from 'react';

import { useColoredBackground } from '@/components/Page';
import { Heading, Image, Link, Show, Text, View } from '@/components/Primitives';
import { Lexical } from '@/components/Primitives/Lexical';
import { ShapeDivider } from '@/components/ShapeDivider';
import { isImage } from '@/js/helper';
import { colors } from '@/js/theme';
import { getReadableColor } from '@/js/theming';

import type { ContainerProps } from './container';
import type { Footer as FooterType } from '@/types/payload-types';

export interface Props extends ContainerProps, FooterType {}

const Footer: React.FC<Props> = (props) => {
	const { lastSection: hasColoredBackground } = useColoredBackground();

	const linkColumns = useMemo(
		() =>
			props.linkColumns?.map((linkColumn) => (
				<View key={linkColumn.title}>
					<Heading level={4} textClassName='text-white'>
						{linkColumn.title}
					</Heading>
					<View className='gap-2'>
						{linkColumn.links?.map(({ link }) => {
							const getHref = () => {
								if (link?.script) {
									return '';
								}

								if (link?.url) {
									return link.url;
								}

								if (link?.page && typeof link.page !== 'number' && link.page.url) {
									return link.page.url;
								}

								return '';
							};

							return (
								<Link
									className='text-white hover:text-neutral-200'
									href={getHref()}
									key={getHref() ?? link?.script}
									script={link?.script ?? undefined}
								>
									{link?.text ?? link?.url}
								</Link>
							);
						})}
					</View>
				</View>
			)),
		[props.linkColumns],
	);

	const officeHours = useMemo(
		() =>
			props.officeHours?.map((entry) => (
				<React.Fragment key={entry.day}>
					<Text
						className='text-white'
						style={{
							gridRow: `span ${entry.items?.length ?? 0}`,
						}}
					>
						{entry.day}
					</Text>

					{entry.items?.map((item) => (
						<Text className='col-start-2 text-white' key={item.hours}>
							{item.hours}
						</Text>
					))}
				</React.Fragment>
			)),
		[props.officeHours],
	);

	return (
		<footer className='relative mt-auto flex flex-col bg-primary-900'>
			<View className='absolute -top-px w-full'>
				<ShapeDivider
					apiUrl={props.apiUrl}
					assetsUrl={props.assetsUrl}
					backgroundColor={hasColoredBackground ? colors.primary['50'] : undefined}
				/>
			</View>

			<View className='m-auto w-full flex-row flex-wrap justify-between gap-16 px-[10vw] pb-16 pt-32'>
				<View className='basis-full items-center gap-8 2xl:basis-64'>
					{isImage(props.logo) && (
						<Link className='w-full max-w-sm' href='/'>
							<Image
								height={props.logo.height ?? 0}
								image={props.logo}
								sizes='22em'
								src={`${props.assetsUrl}${props.logo.url}`}
								width={props.logo.width ?? 0}
							/>
						</Link>
					)}
					<Text className='max-w-[30rem] text-white'>{props.about}</Text>
				</View>

				{linkColumns}

				<View>
					<Heading level={4} textClassName='text-white'>
						{props.officeHoursTitle}
					</Heading>

					<Show when={!!props.officeHoursNoteTop}>
						<Lexical className='mb-4 mt-2 max-w-[16rem] text-white'>{props.officeHoursNoteTop}</Lexical>
					</Show>

					<View className='grid grid-cols-1 gap-x-4 gap-y-2'>{officeHours}</View>

					<Show when={!!props.officeHoursNoteBottom}>
						<Lexical className='mt-4 max-w-[16rem] text-white'>{props.officeHoursNoteBottom}</Lexical>
					</Show>
				</View>
			</View>

			<View
				className='items-center p-4'
				style={{
					backgroundColor: getReadableColor({ color: colors.primary['900'], strength: 0.04 }),
				}}
			>
				<Lexical className='text-sm text-white' pClassName='m-0'>
					{props.additionalInfo}
				</Lexical>
			</View>
		</footer>
	);
};

export { Footer };
