import { defaultElementRenderers, PayloadLexicalReactRenderer } from '@atelier-disko/payload-lexical-react-renderer';
import { config } from 'config';
import React from 'react';

import { Heading, Image, Link, List, ListItem, Paragraph } from '@/components/Primitives';
import { cn } from '@/js/helper';

import type { Page, Paragraph as ParagraphBlock } from '@/types/payload-types';
import type { PayloadLexicalReactRendererContent, PayloadLexicalReactRendererProps } from '@atelier-disko/payload-lexical-react-renderer';

type Props = {
	aClassName?: string;
	children?: ParagraphBlock['content'] | null;
	className?: string;
	h1ClassName?: string;
	h2ClassName?: string;
	h3ClassName?: string;
	h4ClassName?: string;
	liClassName?: string;
	olClassName?: string;
	pClassName?: string;
	ulClassName?: string;
};

const Lexical: React.FC<Props> = ({ children, ...classNames }) => {
	if (!children) {
		return null;
	}

	return (
		<PayloadLexicalReactRenderer
			blockRenderers={{}}
			content={children as PayloadLexicalReactRendererContent}
			elementRenderers={{
				...defaultElementRenderers,
				heading: getHeadingRenderer({ classNames }),
				link: getLinkRenderer({ classNames }),
				list: getListRenderer({ classNames }),
				listItem: getListItemtRenderer({ classNames }),
				paragraph: getParagraphtRenderer({ classNames }),
				upload: getUploadtRenderer({ classNames }),
			}}
		/>
	);
};

const getHeadingLevelFromTag = (tag: string) => {
	switch (tag) {
		case 'h1':
			return 1;
		case 'h2':
			return 2;
		case 'h3':
			return 3;
		case 'h4':
			return 4;
		default:
			return 1;
	}
};

const getHeadingClassNameFromTag = (tag: string) => {
	switch (tag) {
		case 'h1':
			return 'h1ClassName';
		case 'h2':
			return 'h2ClassName';
		case 'h3':
			return 'h3ClassName';
		case 'h4':
			return 'h4ClassName';
		default:
			return 'h1ClassName';
	}
};

type GetRendererArgs = {
	classNames: Omit<Props, 'children'>;
};

type ElementRenderers = NonNullable<PayloadLexicalReactRendererProps<any>['elementRenderers']>;
type HeadingRendererProps = Parameters<ElementRenderers['heading']>['0'];

const getHeadingRenderer = ({ classNames }: GetRendererArgs) =>
	function HeadingRenderer({ children, ...props }: HeadingRendererProps) {
		return (
			<Heading
				className={cn(classNames.className, classNames[getHeadingClassNameFromTag(props.tag)])}
				level={getHeadingLevelFromTag(props.tag)}
			>
				{children}
			</Heading>
		);
	};

type ListRendererProps = Parameters<ElementRenderers['list']>['0'];

const getListRenderer = ({ classNames }: GetRendererArgs) =>
	function ListRenderer({ children }: ListRendererProps) {
		return <List className={cn(classNames.className, classNames.olClassName)}>{children}</List>;
	};

type ListItemRendererProps = Parameters<ElementRenderers['listItem']>['0'];

const getListItemtRenderer = ({ classNames }: GetRendererArgs) =>
	function ListItemRenderer({ children, ...props }: ListItemRendererProps) {
		return <ListItem className={cn(classNames.className, classNames.liClassName)}>{children}</ListItem>;
	};

type ParagraphRendererProps = Parameters<ElementRenderers['paragraph']>['0'];

const getParagraphtRenderer = ({ classNames }: GetRendererArgs) =>
	function ParagraphRenderer({ children, ...props }: ParagraphRendererProps) {
		return <Paragraph className={cn(classNames.className, classNames.pClassName)}>{children}</Paragraph>;
	};

type LinkRendererProps = Parameters<ElementRenderers['link']>['0'];

const getLinkRenderer = ({ classNames }: GetRendererArgs) =>
	function LinkRenderer({ children, ...props }: LinkRendererProps) {
		const getHref = () => {
			if (props.fields.linkType === 'custom') {
				return props.fields.url;
			} else if (props.fields.linkType === 'internal') {
				const page = props.fields.doc.value as Page;

				return page.url ?? '';
			} else {
				return '';
			}
		};

		return (
			<Link className={cn(classNames.className, classNames.aClassName)} href={`${getHref()}`} newTab={props.fields.newTab}>
				{children}
			</Link>
		);
	};

type UploadRendererProps = Parameters<ElementRenderers['upload']>['0'];

const getUploadtRenderer = ({ classNames }: GetRendererArgs) =>
	function UploadRenderer({ ...props }: UploadRendererProps) {
		const image = props.value;

		return (
			<Image
				className={classNames.className}
				height={image.height}
				// @ts-expect-error Type 'string' is not assignable to type 'number'.ts(2322)
				image={image}
				src={`${config.assetsUrl}${image.url}`}
				width={image.width}
			/>
		);
	};

export { Lexical, type Props };
