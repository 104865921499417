import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

import type { Link, Media } from '@/types/payload-types';
import type { ClassValue } from 'clsx';

export const cn = (...inputs: ClassValue[]) => {
	return twMerge(clsx(inputs));
};

export const scrollToHash = (path: string, behavior: ScrollBehavior = 'smooth', throwAfterScroll: boolean = true) => {
	const indexOfHash = path.lastIndexOf('#');

	if (indexOfHash >= 0) {
		const hash = path.substring(indexOfHash + 1);
		let top = 0;

		if (hash) {
			const element = document.getElementById(hash);

			if (element) {
				const rect = element.getBoundingClientRect();

				top = rect.top + window.scrollY;
			}
		}

		if ((hash && top > 0) || (!hash && top === 0)) {
			window.scroll({
				behavior,
				top,
			});

			if (throwAfterScroll) {
				throw 'Scrolling...';
			}
		}
	} else {
		window.scroll({
			behavior,
			top: 0,
		});

		if (throwAfterScroll) {
			throw 'Scrolling...';
		}
	}
};

export const isImage = (image: Media | number | null | undefined): image is Media => {
	return !!image && typeof image === 'object';
};

export const getHref = (link: Link | null | undefined) => {
	if (!link || link.script) {
		return '';
	}

	const page = getRelation(link.page);

	switch (link.type) {
		case 'internal':
			return page?.url ?? '';
		case 'external':
			return link.url ?? '';
		case 'anchor':
			return `${page?.url === '/' ? '' : page?.url ?? ''}/#${link.anchor}`;
		case null:
		case undefined:
			return '';
		default: {
			const _exhaustiveCheck: never = link.type;
		}
	}

	return '';
};

export const getRelation = <T>(relation: number | T | null | undefined) => {
	return typeof relation !== 'number' ? relation ?? null : null;
};
